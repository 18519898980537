.ant-btn {
  border-radius: 30px!important;
  -moz-border-radius: 30px!important;
  -webkit-border-radius: 30px!important;
}

.ant-btn-primary {
  color: #fff;
  border-color: #3366cc!important;
  background: #3366cc!important;
}

.ant-popover-buttons > .ant-btn-primary span {
  color: white !important;
}

.ant-btn-primary > span {
  color: #FFFFFF;
}
